@import-normalize;

@import 'fonts/SFMono/stylesheet.css';
@import 'fonts/inter-display/stylesheet.css';

/* stylelint-disable declaration-no-important */

/* stylelint-disable selector-class-pattern */

@import 'variables';

:root {
  --color-white: #fff;
  --color-red: #f00;
  --color-red-error: rgba(255, 0, 0, 0.7);
  --color-red-required: rgba(255, 0, 0, 0.8);
  --color-red-10: #e53934;
  --color-red-20: #ba201b;
  --color-red-50: #fde4e4;
  --color-white-5: #d9d9d9;
  --color-white-10: #e6e6e6;
  --color-white-15: #f2f2f2;
  --color-white-20: #f5f5f5;
  --color-bf: #f4e6ff;
  --color-black: #1a1a1a;
  --color-black-5: #000;
  --color-black-10: #262626;
  --color-black-20: #323232;
  --color-black-30: #1f1f1f;
  --color-yellow: #fff027;
  --color-yellow-10: #fff459;
  --color-yellow-20: #d4c824;
  --color-yellow-30: #dfd72b;
  --color-yellow-40: #fff575;
  --color-yellow-50: #fffcc2;
  --color-yellow-60: #fdf59b;
  --color-gray: #424242;
  --color-gray-10: #595959;
  --color-gray-20: #5a5a5a;
  --color-gray-30: #666;
  --color-gray-40: #2b2b2b;
  --color-gray-50: #727272;
  --color-gray-60: #4c4c4c;
  --color-gray-70: #9a9a9a;
  --color-gray-80: #ccc;
  --color-purple: #7b6aff;
  --color-purple-10: #5a4ccd;
  --color-blue-60: #dbf1fe;
  --color-blue-55: #b9e6ff;
  --color-blue-50: #d5f2ff;
  --color-blue-30: #00d5e3;
  --color-green-10: #01990f;
  --color-green-50: #defddf;
  --light-gray: #989898;
  --icons-border-color: #d8d8d8;
  --light-color-gray-5: #e5e5e5;
  --light-color-gray-10: #cdcdcd;
  --light-color-gray-101: #cdcdcd75;
  --light-color-gray-15: #b2b2b2;
  --light-color-gray-20: #ebebeb;

  --wave-red: #F26B83;
  --wave-orange: #FBC5C5;
  --wave-yellow: #FDF59B;
  --wave-green: #D4F8C8;
  --wave-blue: #BFEEF8;
  --wave-violet: #E1D9FB;
  --wave-white: var(--color-white-10);
  --wave-multi: var(--color-red-10);

  --shadow-color: #0000001a;
  --focus-color: #a6c1e8;

  --cubic-ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);

  --header-height: 70px;
  --sidebar-width: 486px;
  --page-sidebar-width: 85px;
  --scrollbar-size: 6px;

  --header-sticky-block-height: 100%;

  --z-above: 10;
  --z-below: -10;
  --z-header: 100;
  --z-footer: 200;
  --z-data-popup: 300;
  --z-aside-menu: 400;
  --z-flip-block: 500;
  --z-modal: 1000;
  --z-plot-point-popover: 1010; // More than modal for knee plot
  --z-virtual-scroll-popover: 1020;

  --toastify-toast-width: 375px;

  /* Amplify UI, See: https://ui.docs.amplify.aws/react/theming */
  --amplify-components-button-background-color: var(--color-yellow-60);
  --amplify-components-button-hover-background-color: var(--color-yellow-40);
  --amplify-components-button-hover-color: var(--color-black);
  --amplify-components-button-hover-border-color: var(--color-yellow-40);
  --amplify-components-button-primary-active-background-color: var(--color-yellow-40);
  --amplify-components-button-primary-active-color: var(--color-black);
  --amplify-components-button-primary-focus-background-color: var(--color-yellow-40);
  --amplify-components-button-primary-focus-color: var(--color-black);

  --amplify-components-button-primary-background-color: var(--color-yellow-60);
  --amplify-components-button-primary-color: var(--color-black);
  --amplify-components-button-primary-hover-background-color: var(--color-yellow-40);
  --amplify-components-button-primary-hover-color: var(--color-black);

  --amplify-components-button-link-color: var(--color-black);
  --amplify-components-button-link-hover-color: var(--color-black);
  --amplify-components-button-link-hover-background-color: var(--color-yellow-60);
  --amplify-components-button-link-active-background-color: var(--color-yellow-60);
  --amplify-components-button-link-active-color: var(--color-black);
  --amplify-components-button-link-focus-color: var(--color-black);

  --amplify-components-field-label-color: var(--color-black);
  --amplify-components-textfield-border-color: var(--color-white-15);

  --amplify-components-fieldcontrol-border-color: var(--color-white-15);
  --amplify-components-fieldcontrol-focus-border-color: var(--color-white-15);
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--color-purple);
  --amplify-components-fieldcontrol-quiet-focus-border-block-end-color: var(--color-black);

  --amplify-components-loader-stroke-filled: var(--color-purple);

  --amplify-components-heading-3-font-size: 28px;
  --amplify-components-heading-3-font-weight: 400;

  // Run design 5-th step (Edit caging settings)
  --run-design-advanced-settings-column-width: 1150px;
  --run-design-restricted-advanced-settings-column-width: 1090px;
  --run-design-reset-settings-column-width: 120px;
  --run-design-celltypes-column-width: 470px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
html,
body {
  font-family: 'Inter Display', Arial, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-stretch: normal;
  color: var(--color-black);
  letter-spacing: normal;
}

h1,
h2,
h3,
h4 {
  font-weight: 600;
}

html,
body {
  font-weight: normal;
  background-color: var(--color-white-20);
}

html {
  scrollbar-gutter: stable;
  overflow-y: scroll;

  &.is-hidden,
  &.is-preprocessing {
    padding-right: var(--scrollbar-size);
    overflow: hidden;
    scrollbar-gutter: auto;
  }

  &:has(dialog[open]) {
    overflow: hidden;
    scrollbar-gutter: stable;
  }
}

body {
  min-height: calc(var(--vh, 1vh) * 100);
  line-height: 1.41;
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
}

::-webkit-scrollbar {
  width: var(--scrollbar-size);
  height: var(--scrollbar-size);
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 100px;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-10);
  border-radius: 100px;
}

a {
  color: var(--color-black);
  text-decoration: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  display: block;
  max-width: 100%;
}

ul[class],
ol[class] {
  padding: 0;
  list-style: none;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

button {
  padding: unset;
  cursor: pointer;
  background-color: unset;
  border: none;
}

@media (prefers-reduced-motion: reduce) {
  *:not(.Toastify__progress-bar) {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}

.text-color-light {
  color: var(--light-gray);
}

.text-capitalize {
  text-transform: capitalize;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/* stylelint-enable declaration-no-important */

.rc-tooltip {
  position: absolute;
  z-index: 1070;
  box-sizing: border-box;
  display: block;
  padding: 10px;
  color: var(--color-white);
  visibility: visible;
  background-color: var(--color-black-20);
  border-radius: 6px;

  &-hidden {
    display: none;
  }

  &-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  &-placement-top &-arrow,
  &-placement-topLeft &-arrow,
  &-placement-topRight &-arrow {
    bottom: -5px !important;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: var(--color-black-20);
  }

  &-placement-right &-arrow {
    top: 50%;
  }

  &-placement-rightTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-rightBottom &-arrow {
    bottom: 15%;
  }

  &-placement-left &-arrow,
  &-placement-leftTop &-arrow,
  &-placement-leftBottom &-arrow {
    right: -5px !important;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: var(--color-black-20);
  }

  &-placement-left &-arrow {
    top: 50%;
  }

  &-placement-leftTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-leftBottom &-arrow {
    bottom: 15%;
  }

  &-placement-right &-arrow,
  &-placement-rightTop &-arrow,
  &-placement-rightBottom &-arrow {
    left: -5px !important;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: var(--color-black-20);
    transform: rotate(180deg);
  }

  &-placement-bottom &-arrow,
  &-placement-bottomLeft &-arrow,
  &-placement-bottomRight &-arrow {
    top: -5px !important;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: var(--color-black-20);
  }

  &-placement-bottom &-arrow {
    left: 50%;
  }

  &-placement-bottomLeft &-arrow {
    left: 15%;
  }

  &-placement-bottomRight &-arrow {
    right: 15%;
  }
}

#active-shape path {
  stroke: rgb(255, 0, 0) !important; // important used for override plotly styles in attribute
}

#active-shape text {
  fill: rgb(255, 0, 0) !important; // important used for override plotly styles in attribute
}

.shape-label-text {
  font-weight: 600;
}

.react-tiny-popover-container {
  z-index: 1000 !important; // TEMP: important used for override popover styles in attribute
}

.nsdrag.drag.cursor-ns-resize,
.ewdrag.drag.cursor-ew-resize {
  cursor: pointer !important; // important used for override plotly axis styles
}

.plotly {

  // make minor tick labels for scale type almost invisible
  g.xtick [style*='font-size: 9px'],
  g.ytick [style*='font-size: 9px'] {
    fill: var(--light-color-gray-10) !important;
  }
}

.toast_max-content {
  right: 4em;
  width: 380px;
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.react-selectable {
  border-radius: 10px;
  transition: box-shadow 0.1s ease-out;

  &__selected {
    box-shadow: 0 0 2px 1px var(--color-yellow-10) !important;
  }

  &__copied {
    box-shadow: 0 0 2px 1px var(--color-purple);
  }

  &__by-click {
    pointer-events: none !important;
  }
}

.mouse-select__frame {
  background-color: var(--shadow-color);
  border: 1px solid var(--color-black);
}

.amplify-input {
  background-color: var(--color-white-15);
}

.amplify-heading {
  letter-spacing: 0.5px;
}

.amplify-button {
  &--primary {
    font-weight: bold !important;
  }

  &--link {
    font-weight: normal;
    text-decoration: underline dashed;
    text-underline-offset: 4px;
  }
}

.center-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
