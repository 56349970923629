@import '@/styles/variables';
@import '@/styles/dashboard';

.dashboard {
  --page-header-height: 70px;
  --page-footer-height: 65px;
  --gap: 20px;

  @include dashboard;
  height: calc(100vh - var(--page-header-height) - var(--page-footer-height) - var(--gap));

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    padding-top: 20px;
  }
}

.content-block {
  position: relative;
  padding: 20px;
  background-color: var(--color-white);
  border-radius: 20px;
}

.instrument {
  &__name {
    @include title-format;
    @include resizeLinearMd(font-size, 30, 38);
    padding-bottom: 20px;
    font-size: 38px;
    font-weight: bold;
    border-bottom: solid 1px var(--color-black-20);
  }

  &__details {
    display: grid;
    grid-template-columns: .8fr .8fr 1fr;
    padding: 20px 0 0;
  }

  &__details-block {
    position: relative;
    padding: 0 25px;

    &::before {
      position: absolute;
      left: 0;
      width: 1px;
      height: 100%;
      content: '';
      background-color: var(--color-black-20);
    }

    &:first-child {
      padding-left: 0;

      &::before {
        background-color: unset;
      }
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 50px;
    padding: 2px 0;
    font-size: 20px;

    & span {
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
    }
  }

  &__status {
    justify-self: start;
  }
}

.sensors {
  flex-grow: 1;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-bottom: solid 1px var(--color-black-20);
  }

  &__name {
    @include title-format;
    @include resizeLinearMd(font-size, 22, 30);

    padding-bottom: 20px;
    font-size: 30px;
    font-weight: bold;
  }

  &__list-container {
    height: calc(100% - 80px);
    overflow-y: scroll;
  }

  &__list {
    column-gap: 30px;
    padding: 15px 0;
    columns: 2 auto;
  }

  &__list-item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    width: 100%;
    padding: 16px 0;
    font-size: 21px;
    font-weight: normal;
    letter-spacing: 0.25px;
    border-bottom: solid 1px var(--light-color-gray-10);
    transition: border-bottom 0.3s;

    &:hover {
      border-bottom: solid 1px var(--color-black);
    }
  }

  &__telemetry-name {
    align-self: baseline;
    width: max-content;
    color: var(--light-gray);
    text-align: left;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
  }

  &__telemetry-time {
    padding-right: 5px;
    font-size: 0.8em;
    line-height: 1.6;
    color: var(--light-gray);
    text-align: right;
  }
}

.loader {
  @include loader;
  width: max-content;
  height: 100%;
  margin: auto;
  background-color: unset;
  padding-block: 20px;
}

.no-data {
  @include no-data-block;
}
