@import '@/styles/variables';

.filters {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  font-size: 20px;

  &__input {
    max-width: 200px;
    padding: 5px 15px;
    color: var(--input-color);
    border: 1px solid var(--icons-border-color);
    border-radius: 10px;

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }
  }

  &__calendar {
    background-color: var(--color-white);
  }

  &__popper {
    z-index: 2;
  }
}
