@import '@/styles/badge';

.status {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: end;
  color: var(--light-gray);
  cursor: default;

  &__badge {
    @include badge;

    display: flex;
    gap: 4px;
    align-items: center;
    line-height: normal;
    white-space: nowrap;
  }

  &__info {
    --info-button-size: 16px;
  }

  &__icon {
    height: 19px;
    line-height: normal;

    &_aligned {
      width: 19px;
      text-align: center;
    }
  }
}
