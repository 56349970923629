.experiment-page-header {
  &__title {
    display: flex;
    gap: 10px;
  }

  &__status {
    padding-top: 12px;
  }

  &__controls {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 20px;
  }

  &__assay-info {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    align-items: start;
  }

  &__assay-icons {
    margin-top: 12px;
  }

  &__metadata {
    margin-top: 20px;

    &_second {
      --metadata-grid-template-columns: 1fr 2fr;
    }
  }
}

.button {
  display: flex;
  gap: 10px;
  justify-content: center !important;
  width: 120px;
  height: 45px;
  padding: 10px 15px;
  color: var(--color-black);
  cursor: pointer;
  background-color: var(--color-yellow-40);
  border-color: var(--color-white-20);
}

.select {
  width: 95px;
  background-color: var(--color-white);
  border-radius: 10px;
}
