@import '@/styles/variables';

.modal {
  min-width: 90vw;
  height: 70vh;
  padding: 20px;
  background-color: var(--color-white);

  &__header {
    display: flex;
    gap: 35px;
    align-items: baseline;
    justify-content: space-between;
  }

  &__header-titles {
    display: flex;
    gap: 35px;
    align-items: center;
  }

  &__header-title {
    position: relative;
    font-size: 30px;
    font-weight: bold;

    &::after {
      position: absolute;
      top: 5px;
      right: -20px;
      width: 1px;
      height: 40px;
      content: '';
      background-color: var(--color-black);
    }
  }

  &__header-instrument {
    padding-top: 5px;
    font-size: 24px;
    font-weight: 300;
    line-height: 29px;
  }

  &__content {
    overflow: hidden;
  }

  &__chart {
    height: 100% ;
  }
}


.skeleton {
  --header-height: 100px;
  --padding-block: 20px;
  --padding-inline: 40px;

  position: absolute;
  top: 100px;
  width: calc(100% - var(--padding-inline));
  height: calc(100% - var(--header-height) - var(--padding-block));
}

.no-data {
  @include no-data-block;
}
